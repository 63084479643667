import { formateaIdsPeriodoHuman } from "@/utils";

const COLOR = {
  ROJO: "#e74c3c",
  AZUL: "#3498db",
  AMARILLO: "#ffc300",
  NARANJA: "#ff7133",
  VERDE: "#27ae60",
  MORADO: "#9A12FF"
};

/**
 * generaGraficosLine
 * @param {Array} entrada
 */
export const generaGraficosLine = (entrada = []) => {
  return {
    valoresEjeX: entrada.map((p) => formateaIdsPeriodoHuman(p.idperiodo)),
    nroAtencion: entrada.map((p) => Number(p.resultado_numeroatencion)),
    nroAExamen: entrada.map((p) => Number(p.resultado_numeroexamen)),
    nroSuplemento: entrada.map((p) => Number(p.resultado_numerosuplemento)),
    nroAvance: entrada.map((p) => Number(p.avance))
  };
};

/**
 * optionGraphic
 * @param {String} title titulo de la grafica
 * @param {Object} valoresGraficas tiene 3 ararys
 * @returns
 */
export const optionGraphic = (title = "Titulo de graficos", valoresGraficas = {}) => {
  return {
    title: {
      text: title,
      left: "center",
      textStyle: {
        fontSize: 14
      }
    },
    tooltip: {
      trigger: "axis"
    },
    toolbox: {
      show: true,
      orient: "vertical",
      left: "right",
      top: "center",
      feature: {
        mark: { show: true },
        magicType: { show: true, type: ["line", "bar"] },
        restore: { show: true },
        saveAsImage: { show: true }
      }
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: valoresGraficas.valoresEjeX,
      axisLabel: {
        margin: 8.5,
        fontSize: 10
      }
    },
    yAxis: {
      type: "value",
      name: "Porcentaje",
      max: 120,
      axisLabel: {
        margin: 8.5,
        fontSize: 10
      }
    },
    series: [
      {
        name: "Paquete (%)",
        type: "line",
        data: valoresGraficas.nroAvance,
        label: {
          show: true,
          position: "top",
          formatter: "{c} %",
          color: COLOR.ROJO,
          padding: [4, 5],
          borderRadius: 5,
          fontSize: 10
        },
        lineStyle: {
          color: COLOR.ROJO
        },
        itemStyle: {
          borderWidth: 5,
          color: COLOR.ROJO
        }
      },
      {
        name: "Examen (%)",
        type: "line",
        data: valoresGraficas.nroAExamen,
        label: {
          show: true,
          position: "middle",
          formatter: "{c} %",
          fontSize: 10
        },
        lineStyle: {
          color: COLOR.MORADO
        },
        itemStyle: {
          borderWidth: 3,
          color: COLOR.MORADO
        }
      },
      {
        name: "Suplemento (%)",
        type: "line",
        data: valoresGraficas.nroSuplemento,
        label: {
          show: true,
          position: "top",
          formatter: "{c} %",
          color: COLOR.AMARILLO,
          fontSize: 10
        },
        lineStyle: {
          color: COLOR.AMARILLO
        },
        itemStyle: {
          borderWidth: 3,
          color: COLOR.AMARILLO
        }
      },
      {
        name: "4 APN (%)",
        type: "line",
        data: valoresGraficas.nroAtencion,
        label: {
          show: true,
          position: "bottom",
          color: COLOR.VERDE,
          formatter: "{c} %",
          fontSize: 10
        },
        lineStyle: {
          color: COLOR.VERDE
        },
        itemStyle: {
          borderWidth: 3,
          color: COLOR.VERDE
        }
      }
    ]
  };
};

/**
 * generaGraficosLineValor
 * @param {Array} entrada
 */
export const generaGraficosLineValor = (entrada = []) => {
  return {
    valoresEjeX: entrada.map((p) => formateaIdsPeriodoHuman(p.idperiodo)),
    nroAtencion: entrada.map((p) => Number(p.numerador_numeroatencion)),
    nroAExamen: entrada.map((p) => Number(p.numerador_numeroexamen)),
    nroSuplemento: entrada.map((p) => Number(p.numerador_numerosuplemento)),
    numerador: entrada.map((p) => Number(p.numerador))
  };
};

// https://codesandbox.io/s/vue-echartsshiyong-bfu3r?file=/src/components/constans.js:0-2502
export const optionGraphicValor = (title = "Titulo de graficos", valoresGraficas = {}) => {
  return {
    title: {
      text: title,
      left: "center",
      textStyle: {
        fontSize: 14
      }
    },
    tooltip: {
      trigger: "axis"
    },
    toolbox: {
      feature: {
        dataView: { show: true, readOnly: false },
        magicType: { show: true, type: ["line", "bar"] },
        restore: { show: true },
        saveAsImage: { show: true }
      }
    },
    xAxis: [
      {
        type: "category",
        data: valoresGraficas.valoresEjeX,
        axisPointer: {
          type: "shadow"
        },
        triggerEvent: true
      }
    ],
    yAxis: {
      type: "value",
      name: "Cantidad",
      axisLabel: {
        margin: 8.5,
        fontSize: 10
      }
    },
    series: [
      {
        name: "Paquete",
        type: "bar",
        barWidth: "10px",
        data: valoresGraficas.numerador,
        itemStyle: {
          color: COLOR.ROJO
        }
      },
      {
        name: "Examen",
        type: "bar",
        barWidth: "10px",
        data: valoresGraficas.nroAExamen,
        itemStyle: {
          color: COLOR.MORADO
        }
      },
      {
        name: "Suplemento",
        type: "bar",
        barWidth: "10px",
        data: valoresGraficas.nroSuplemento,
        itemStyle: {
          color: COLOR.AMARILLO
        }
      },
      {
        name: "4 APN",
        type: "bar",
        barWidth: "10px",
        data: valoresGraficas.nroAtencion,
        itemStyle: {
          color: COLOR.VERDE
        }
      }
    ]
  };
};

export const generaLeyendaPaquete = () => {
  return [
    {
      color: COLOR.ROJO,
      label: "% de mujeres gestantes c/paquete integrado de servicios"
    },
    {
      color: COLOR.MORADO,
      label: "% de mujeres gestantes recibieron examenes auxiliares (1er trimestre)"
    },
    {
      color: COLOR.VERDE,
      label: "% de mujeres gestantes del denominador que durante su gestación, recibieron 4 APN"
    },
    {
      color: COLOR.AMARILLO,
      label: "% de mujeres gestantes que recibieron suplementación"
    }
  ];
};

/**
 * generaGraficosLine
 * @param {Array} entrada
 */
export const generaGraficosLineNinio = (entrada = []) => {
  return {
    valoresEjeX: entrada.map((p) => formateaIdsPeriodoHuman(p.idperiodo)),
    nroCred: entrada.map((p) => Number(p.resultado_numerocred)),
    nroVacuna: entrada.map((p) => Number(p.resultado_numerovacuna)),
    nroSuplemento: entrada.map((p) => Number(p.resultado_numerosuplemento)),
    nroDosaje: entrada.map((p) => Number(p.resultado_numerodosaje)),
    nroAvance: entrada.map((p) => Number(p.avance))
  };
};

/**
 * optionGraphic
 * @param {String} title titulo de la grafica
 * @param {Object} valoresGraficas tiene 3 ararys
 * @returns
 */
export const optionGraphicNinio = (title = "Titulo de graficos", valoresGraficas = {}) => {
  return {
    title: {
      text: title,
      left: "center",
      textStyle: {
        fontSize: 14
      }
    },
    tooltip: {
      trigger: "axis"
    },
    toolbox: {
      show: true,
      orient: "vertical",
      left: "right",
      top: "center",
      feature: {
        mark: { show: true },
        magicType: { show: true, type: ["line", "bar"] },
        restore: { show: true },
        saveAsImage: { show: true }
      }
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: valoresGraficas.valoresEjeX,
      axisLabel: {
        margin: 8.5,
        fontSize: 10
      }
    },
    yAxis: {
      type: "value",
      name: "Porcentaje",
      // max: 120,
      axisLabel: {
        margin: 8.5,
        fontSize: 10
      }
    },
    series: [
      {
        name: "Paquete (%)",
        type: "line",
        data: valoresGraficas.nroAvance,
        label: {
          show: true,
          position: "top",
          formatter: "{c} %",
          color: COLOR.ROJO,
          padding: [4, 5],
          borderRadius: 5,
          fontSize: 10
        },
        lineStyle: {
          color: COLOR.ROJO
        },
        itemStyle: {
          borderWidth: 5,
          color: COLOR.ROJO
        }
      },
      {
        name: "Vacuna (%)",
        type: "line",
        data: valoresGraficas.nroVacuna,
        label: {
          show: true,
          position: "middle",
          formatter: "{c} %",
          fontSize: 10
        },
        lineStyle: {
          color: COLOR.MORADO
        },
        itemStyle: {
          borderWidth: 3,
          color: COLOR.MORADO
        }
      },
      {
        name: "Suplemento (%)",
        type: "line",
        data: valoresGraficas.nroSuplemento,
        label: {
          show: true,
          position: "top",
          formatter: "{c} %",
          color: COLOR.AMARILLO,
          fontSize: 10
        },
        lineStyle: {
          color: COLOR.AMARILLO
        },
        itemStyle: {
          borderWidth: 3,
          color: COLOR.AMARILLO
        }
      },
      {
        name: "Dosaje (%)",
        type: "line",
        data: valoresGraficas.nroDosaje,
        label: {
          show: true,
          position: "bottom",
          color: COLOR.VERDE,
          formatter: "{c} %",
          fontSize: 10
        },
        lineStyle: {
          color: COLOR.VERDE
        },
        itemStyle: {
          borderWidth: 3,
          color: COLOR.VERDE
        }
      },
      {
        name: "CRED (%)",
        type: "line",
        data: valoresGraficas.nroCred,
        label: {
          show: true,
          position: "bottom",
          formatter: "{c} %",
          fontSize: 10
        },
        lineStyle: {
          color: COLOR.AZUL
        },
        itemStyle: {
          borderWidth: 3,
          color: COLOR.AZUL
        }
      }
    ]
  };
};

/**
 * generaGraficosLineNinioValor
 * @param {Array} entrada
 */
export const generaGraficosLineNinioValor = (entrada = []) => {
  return {
    valoresEjeX: entrada.map((p) => formateaIdsPeriodoHuman(p.idperiodo)),
    nroCred: entrada.map((p) => Number(p.numerocred)),
    nroVacuna: entrada.map((p) => Number(p.numerovacuna)),
    nroSuplemento: entrada.map((p) => Number(p.numerosuplemento)),
    nroDosaje: entrada.map((p) => Number(p.numerodosaje)),
    numerador: entrada.map((p) => Number(p.numerador))
  };
};

export const optionGraphicNinioValor = (title = "Titulo de graficos", valoresGraficas = {}) => {
  return {
    title: {
      text: title,
      left: "center",
      textStyle: {
        fontSize: 14
      }
    },
    tooltip: {
      trigger: "axis"
    },
    toolbox: {
      feature: {
        dataView: { show: true, readOnly: false },
        magicType: { show: true, type: ["line", "bar"] },
        restore: { show: true },
        saveAsImage: { show: true }
      }
    },
    xAxis: [
      {
        type: "category",
        data: valoresGraficas.valoresEjeX,
        axisPointer: {
          type: "shadow"
        },
        triggerEvent: true
      }
    ],
    yAxis: {
      type: "value",
      name: "Cantidad",
      axisLabel: {
        margin: 8.5,
        fontSize: 10
      }
    },
    series: [
      {
        name: "Paquete",
        type: "bar",
        barWidth: "10px",
        data: valoresGraficas.numerador,
        itemStyle: {
          color: COLOR.ROJO
        }
      },
      {
        name: "Vacuna",
        type: "bar",
        barWidth: "10px",
        data: valoresGraficas.nroVacuna,
        itemStyle: {
          color: COLOR.MORADO
        }
      },
      {
        name: "Suplemento",
        type: "bar",
        barWidth: "10px",
        data: valoresGraficas.nroSuplemento,
        itemStyle: {
          color: COLOR.AMARILLO
        }
      },
      {
        name: "Dosaje",
        type: "bar",
        barWidth: "10px",
        data: valoresGraficas.nroDosaje,
        itemStyle: {
          color: COLOR.VERDE
        }
      },
      {
        name: "CRED",
        type: "bar",
        barWidth: "10px",
        data: valoresGraficas.nroCred,
        itemStyle: {
          color: COLOR.AZUL
        }
      }
    ]
  };
};

export const generaLeyendaPaqueteNinio = () => {
  return [
    {
      color: COLOR.ROJO,
      label: "% de niños c/ paquete integrado de servicios"
    },
    {
      color: COLOR.MORADO,
      label: "% de niños c/ Vacuna completa para su edad"
    },
    {
      color: COLOR.VERDE,
      label: "% de niños c/ dosaje de hemoglobina para su edad"
    },
    {
      color: COLOR.AZUL,
      label: "% de niños c/ CRED para su edad"
    },
    {
      color: COLOR.AMARILLO,
      label: "% de niños c/ Suplementación completa para su edad"
    }
    // {
    //   color: COLOR.AZUL,
    //   label: "%N° de niños c/ DNI emitido"
    // },
  ];
};
