<template>
  <div class="flex flex-wrap gap-8">
    <router-link :to="'/indicador/' + idindicador + '/detalles'" class="text-center">
      <div class="text-3xl text-green-400">
        <FileSearchOutlined />
      </div>
      <p>
        Ver más <br />
        detalles
      </p>
    </router-link>

    <router-link :to="'/indicador/' + idindicador + '/graficas'" class="text-center">
      <div class="text-3xl text-indigo-400">
        <LineChartOutlined />
      </div>
      <p>
        Ver más<br />
        Gráficas
      </p>
    </router-link>

    <button @click="handleReporteExcel" class="text-center" :disabled="isAccionsDisabled">
      <div
        class="text-3xl"
        :class="[isAccionsDisabled ? 'text-gray-400 cursor-not-allowed' : 'text-green-700']"
      >
        <FileExcelOutlined />
      </div>
      <p>
        Generar<br />
        reporte
      </p>
    </button>

    <button
      @click="showModalPrintModal"
      type="submit"
      class="text-center"
      :disabled="isAccionsDisabled"
    >
      <div
        class="text-3xl"
        :class="[isAccionsDisabled ? 'text-gray-400 cursor-not-allowed' : 'text-red-400']"
      >
        <FilePdfOutlined />
      </div>
      <p>
        Visualizar<br />
        PDF
      </p>
    </button>

    <button @click="showModalPeriodoEvaluacion" class="text-center" :disabled="isAccionsDisabled">
      <div
        class="text-3xl"
        :class="[isAccionsDisabled ? 'text-gray-400 cursor-not-allowed' : 'text-blue-400']"
      >
        <HistoryOutlined />
      </div>
      <p>
        Periodo de <br />
        evaluación
      </p>
    </button>

    <button @click="handleReporteIndicador" class="text-center">
      <div class="text-3xl" :class="'text-green-500'">
        <FileTextOutlined />
      </div>
      <p>
        Visualizar <br />
        Ficha
      </p>
    </button>
  </div>
</template>

<script>
import {
  HistoryOutlined,
  FileSearchOutlined,
  FileExcelOutlined,
  FilePdfOutlined,
  FileTextOutlined,
  LineChartOutlined
} from "@ant-design/icons-vue";

export default {
  components: {
    FileSearchOutlined,
    FileExcelOutlined,
    FilePdfOutlined,
    HistoryOutlined,
    FileTextOutlined,
    LineChartOutlined
  },
  props: {
    idindicador: {
      type: String,
      default: ""
    },
    sigla: {
      type: String,
      default: ""
    },
    isAccionsDisabled: {
      type: Boolean,
      default: true
    }
  },
  emits: [
    "handleReporteExcel",
    "showModalPeriodoEvaluacion",
    "handleReportePDF",
    "showModalPrintModal"
  ],
  setup(props, { emit }) {
    const handleReporteIndicador = () => {
      window.open(`${process.env.VUE_APP_BASE_API}/FICHAS/${props.sigla}.pdf`, "_blank");
    };

    return {
      handleReporteExcel: () => emit("handleReporteExcel"),
      showModalPeriodoEvaluacion: () => emit("showModalPeriodoEvaluacion"),
      handleReportePDF: () => emit("handleReportePDF"),
      showModalPrintModal: () => emit("showModalPrintModal"),
      handleReporteIndicador
    };
  }
};
</script>

<style></style>
