<template>
  <a-page-header @back="() => $router.go(-1)" title="INDICADOR RESUMEN" class="pt-0 px-0">
    <template #extra>
      <a-breadcrumb>
        <a-breadcrumb-item><router-link to="/menu">Proyecto</router-link></a-breadcrumb-item>
        <a-breadcrumb-item><router-link to="/indicador">Indicador</router-link></a-breadcrumb-item>
        <a-breadcrumb-item>Resumen</a-breadcrumb-item>
      </a-breadcrumb>
    </template>
  </a-page-header>

  <!--------------- Filtros --------------->
  <div class="flex flex-wrap mb-4">
    <fieldset
      class="border border-solid w-full border-gray-300 flex justify-center items-center px-8 lg:w-auto"
    >
      <legend class="text-sm text-center m-0">Filtros</legend>
      <IndicadorResumenFiltro
        :idperiodoinicio="idperiodoinicio"
        :sigla="indicadorData.sigla"
        :periodos="periodos"
        @changedistrito="changedistrito"
        @changePeriodo="changePeriodo"
        @changeprovincia="changeprovincia"
      />
    </fieldset>

    <fieldset
      class="border border-solid w-full mt-3 border-gray-300 p-3 px-8 flex justify-center md:mt-0 lg:w-auto"
    >
      <legend class="text-sm text-center">Acciones</legend>

      <IndicadorResumenAcciones
        :idindicador="idindicador"
        :sigla="indicadorData.sigla"
        :isAccionsDisabled="isAccionsDisabled"
        @showModalPrintModal="showModalPrintModal"
        @handleReporteExcel="handleReporteExcel"
        @showModalPeriodoEvaluacion="showModalPeriodoEvaluacion"
      />
    </fieldset>
  </div>

  <div v-if="isAccionsDisabled && !isLoading">
    <a-result
      status="info"
      title="Aún no hay información para mostrar"
      sub-title="Primero debes crear el query y la sigla en la base de datos para el presente indicador para luego ejecutarlo en 'Periodo de evaluación'"
    >
      <template #extra>
        <router-link to="/indicador">
          <a-button type="primary">Ir a indicadores</a-button>
        </router-link>
      </template>
    </a-result>
  </div>

  <div v-else>
    <TituloPorcentajeIndicador
      :isLoading="isLoading"
      :porcentaje="porcentaje"
      :abreviatura="indicadorData.abreviatura"
      :denominacion="indicadorData.denominacion"
      :numerador="numerador"
      :titulonumerado="indicadorData.titulonumerado"
      :denominador="denominador"
      :titulodenominado="indicadorData.titulodenominado"
      :porcentajeColor="porcentajeColor"
      :sigla="indicadorData.sigla"
    />

    <a-result
      v-if="!periodos.length && !isPeriodoProcessing"
      status="info"
      title="No hay información para mostrar"
      sub-title="Puedes generar un periodo de evaluación para poder ver la información"
    >
      <template #extra>
        <a-button @click="showModalPeriodoEvaluacion" type="primary">
          Crear periodo de evaluación
        </a-button>
      </template>
    </a-result>

    <a-result
      v-if="isPeriodoProcessing"
      title="Procesando indicador"
      sub-title="Se está ejecutando el query para generar la información necesaria del indicador"
    >
      <template #icon>
        <AppLoading />
      </template>

      <template #extra>
        <a-button @click="showModalPeriodoEvaluacion" type="primary">
          Ver periodo de evaluación
        </a-button>
      </template>
    </a-result>

    <fieldset
      class="w-full flex flex-col lg:flex-row gap-6 mb-4 border-2"
      v-if="paquetesIntegrados.length"
    >
      <legend class="text-xs font-bold">Actividades:</legend>

      <div
        v-for="paquete in paquetesIntegrados"
        :key="paquete.id"
        class="flex gap-5 items-center p-3 bg-white rounded-xl border-4 border-gray-200"
      >
        <div class="text-lg font-bold">{{ paquete.valor }}</div>
        <p>{{ paquete.texto }}</p>
      </div>
    </fieldset>

    <PaquetePreventivo
      v-if="esIndicadorMC.ok"
      :escalaColores="escalaColoresPaquete"
      :optionsGrafico="optionsGrafico"
      :optionsGraficoValor="optionsGraficoValor"
      :title="esIndicadorMC.title"
    />

    <div v-if="periodos.length && !isPeriodoProcessing" class="lg:flex gap-8">
      <div class="w-full lg:w-1/2">
        <div class="text-center mt-2">
          <h1 class="p-2 bg-white font-semibold border border-gray-400">
            Indicador por distritos ({{ distritos.length }})
          </h1>
          <span><CaretDownOutlined /></span>
        </div>

        <div class="max-w-full px-4 py-4 mx-auto overflow-x-auto">
          <a-table
            :columns="columnsIndicadorDistrito"
            :data-source="distritos"
            size="small"
            :pagination="{ size: 'large' }"
            rowKey="distrito"
            :rowClassName="
              (_record, index) =>
                index % 2 === 0 ? 'cursor-pointer bg-white' : 'cursor-pointer bg-gray-100'
            "
          >
            <template #rangepages="{ record }">
              <span> {{ distritos.indexOf(record) + 1 }} </span>
            </template>

            <template #porcentaje="{ record, text: porcentaje }">
              <div class="flex gap-4 justify-between items-center">
                <div
                  class="w-3 h-3 rounded-full"
                  :style="{ background: record.porcentajeHex }"
                ></div>
                <p class="font-bold">{{ porcentaje }} % &nbsp;</p>
              </div>
            </template>
            <template #numeradordenominador="{ record }">
              <p class="text-xs text-gray-400 text-right">
                {{ record.total_numerador }} /
                {{ record.total_denominador }}
              </p>
            </template>
          </a-table>
        </div>
      </div>

      <div class="w-full lg:w-1/2 max-w-full mx-auto overflow-x-auto lg:overflow-hidden">
        <div class="text-center mt-2">
          <h1 class="p-2 bg-white font-semibold border border-gray-400">
            Evolución del indicador en el periodo de evaluación {{ rangoPeriodo }}
          </h1>
          <span><CaretDownOutlined /></span>
        </div>
        <div class="w-full">
          <v-chart
            :option="localOptionGraphic"
            style="width: 100%; height: 400px; background-color: white"
          />
        </div>

        <h4 class="text-base my-2">Leyenda:</h4>
        <div class="flex gap-3 flex-wrap">
          <div v-for="escala in escalaColores" :key="escala.texto" class="flex gap-1 items-center">
            <div
              class="w-4 h-4 rounded-full border border-white"
              :style="{ backgroundColor: escala.color }"
            ></div>
            {{ escala.texto }}
          </div>
        </div>
      </div>
    </div>

    <div class="w-full">
      <div class="text-center mt-2">
        <h1 class="p-2 bg-white font-semibold border border-gray-400">
          Indicador por establecimientos ({{ establecimientos.length }})
        </h1>
        <span><CaretDownOutlined /></span>
      </div>

      <a-table
        size="small"
        rowKey="establecimiento"
        :columns="columnsIndicadorEstablecimiento"
        :data-source="establecimientos"
        :pagination="{ size: 'large' }"
        :custom-row="customRow"
        :loading="isLoading"
        :rowClassName="
          (_record, index) =>
            index % 2 === 0 ? 'cursor-pointer bg-white' : 'cursor-pointer bg-gray-100'
        "
      >
        <template #rangepages="{ record }">
          <p class="text-center">{{ establecimientos.indexOf(record) + 1 }}</p>
        </template>

        <template #porcentaje="{ record, text: porcentaje }">
          <div class="flex gap-4 justify-between items-center">
            <div class="w-3 h-3 rounded-full" :style="{ background: record.porcentajeHex }"></div>
            <p class="font-bold">{{ porcentaje }} % &nbsp;</p>
          </div>
        </template>
        <template #numeradordenominador="{ record }">
          <p class="text-xs text-gray-400 text-right">
            {{ record.total_numerador_establecimiento }} /
            {{ record.total_denominador_establecimiento }}
          </p>
        </template>
      </a-table>
    </div>

    <PeriodoEvaluacion
      v-if="isVisible"
      :isVisible="isVisible"
      :query="indicadorData.query"
      :sigla="indicadorData.sigla"
      :idindicador="idindicador"
      @closeModal="closeModalPeriodoEvaluacion"
      @getResumenIndicador="getResumenIndicador"
    />

    <a-modal
      :visible="isVisiblePrintModal"
      @close="closeModalPrintModal"
      @cancel="closeModalPrintModal"
      :footer="null"
      class="max-w-sm md:max-w-5xl text-center"
      width="100%"
    >
      <a-button
        shape="round"
        v-print="objPrintIndicadorResumen"
        size="large"
        class="text-center mx-auto mb-8 bg-red-500 text-white"
      >
        <template #icon>
          <PrinterOutlined />
          Imprimir
        </template>
      </a-button>

      <div id="printIndicadorResumen">
        <PrintIndicadorResumen :indicadorEstablecimientos="establecimientos">
          <template #header>
            <TituloPorcentajeIndicador
              :isLoading="isLoading"
              :porcentaje="porcentaje"
              :abreviatura="indicadorData.abreviatura"
              :denominacion="indicadorData.denominacion"
              :numerador="numerador"
              :titulonumerado="indicadorData.titulonumerado"
              :denominador="denominador"
              :titulodenominado="indicadorData.titulodenominado"
              :porcentajeColor="porcentajeColor"
            />
          </template>

          <template #grafico>
            <v-chart
              :option="localOptionGraphic"
              style="width: 100%; height: 400px; background-color: white"
            />
          </template>
        </PrintIndicadorResumen>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { ref, toRaw, onMounted, reactive, watch, onUnmounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import IndicadorResumenFiltro from "./IndicadorResumenFiltro.vue";
import PeriodoEvaluacion from "./IndicadorResumenPeriodoEvaluacion.vue";
import IndicadorResumenAcciones from "./IndicadorResumenAcciones.vue";
import PrintIndicadorResumen from "@/print/PrintIndicadorResumen.vue";
import TituloPorcentajeIndicador from "@/components/TituloPorcentajeIndicador.vue";
import PaquetePreventivo from "@/components/PaquetePreventivo.vue";
import router from "@/router";
import ApiIndicador from "@/api/indicador";
import MapaApi from "@/api/mapa";
import ApiDescargas from "@/api/descargas";
import EstadisticoApi from "@/api/estadistico";
import {
  calculaPorcentaje,
  semaforoByPorcentajeIndicador,
  calculaRangoPeriodos,
  generaArgumentoOptionsBar,
  semaforoMapa,
  generaLeyendaSemaforo
} from "@/utils";
import {
  columnsIndicadorEstablecimiento,
  columnsIndicadorDistrito,
  optionsBar
} from "./utilsIndicador";
import {
  generaGraficosLine,
  optionGraphic,
  optionGraphicValor,
  generaGraficosLineValor,
  generaLeyendaPaquete,
  generaGraficosLineNinio,
  optionGraphicNinioValor,
  generaGraficosLineNinioValor,
  optionGraphicNinio,
  generaLeyendaPaqueteNinio
} from "@/components/utilsPaquetePreventivo";
import { CaretDownOutlined, PrinterOutlined } from "@ant-design/icons-vue";
import AppLoading from "@/components/AppLoading.vue";

export default {
  components: {
    AppLoading,
    PeriodoEvaluacion,
    PrintIndicadorResumen,
    IndicadorResumenFiltro,
    IndicadorResumenAcciones,
    TituloPorcentajeIndicador,
    PaquetePreventivo,
    // icons
    CaretDownOutlined,
    PrinterOutlined
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const idindicador = ref("");
    const establecimientos = ref([]);
    const isVisiblePrintModal = ref(false);

    const isVisible = ref(false);
    const isLoadingGrafico = ref(false);
    const isPeriodoProcessing = ref(false);
    const porcentaje = ref("");
    const numerador = ref("");
    const denominador = ref("");
    const isLoading = ref(false);
    const isAccionsDisabled = ref(true);
    const localOptionGraphic = ref(null);
    const periodos = ref([]);
    const idperiodoinicio = ref("");
    const escalaColores = ref([]);
    const optionsGrafico = ref(null);
    const optionsGraficoValor = ref(null);
    const escalaColoresPaquete = ref([]);
    const esIndicadorMC = reactive({
      ok: false,
      tipo: "",
      title: ""
    });

    const distritos = ref([]);

    var txt = "";

    const coloresSemaforo = reactive({
      RANGO_SINDATO: "RANGO_SINDATO",
      RANGO_BAJO: "RANGO_BAJO",
      RANGO_MEDIO: "RANGO_MEDIO",
      RANGO_ALTO: "RANGO_ALTO"
    });

    const coloresSemaforoMapa = reactive({
      DENOMINADOR: "5",
      RANGO_SINDATO: "5",
      RANGO_BAJO: "10",
      RANGO_MEDIO: "20",
      RANGO_ALTO: "30"
    });

    const porcentajeColor = ref("");
    const rangoPeriodo = ref("");

    const paquetesIntegrados = ref([]);

    const objPrintIndicadorResumen = reactive({
      id: "printIndicadorResumen",
      popTitle: "SIVINUR | RESUMEN DE INDICADOR"
    });

    const indicadorData = reactive({
      denominacion: "",
      fichatecnica: "",
      titulonumerado: "",
      titulodenominado: "",
      tituloreporte: "",
      abreviatura: "",
      query: "",
      sigla: ""
    });

    idindicador.value = route.params?.indicador;

    const getResumenIndicador = () => {
      store.dispatch("mapa/setUbigeo", "");
      isLoading.value = true;

      ApiIndicador.getInfo(toRaw(idindicador.value))
        .then((response) => {
          indicadorData.denominacion = response.data[0].denominacion;
          indicadorData.query = response.data[0].query;
          indicadorData.fichatecnica = response.data[0].fichatecnica;
          indicadorData.titulonumerado = response.data[0].titulonumerado;
          indicadorData.titulodenominado = response.data[0].titulodenominado;
          indicadorData.tituloreporte = response.data[0].tituloreporte;
          indicadorData.abreviatura = response.data[0].abreviatura;
          indicadorData.sigla = response.data[0].sigla;

          store.dispatch("mapa/setSigla", response.data[0].sigla);

          fetchPeriodos(indicadorData.sigla);
          isAccionsDisabled.value = !response.data[0].query && response.data[0].sigla == null;

          changePeriodo();
          changeGrafica();
        })
        .catch((err) => console.log(err))
        .finally(() => (isLoading.value = false));
    };

    const fetchPaqueteIntegrado = (abreviatura) => {
      if (abreviatura === "MC-01.01" || abreviatura === "MC-02.01") {
        const provincia = store.getters["mapa/provincia"];
        const distrito = store.getters["mapa/distrito"];
        const quintil = store.getters["mapa/quintil"];
        const periodo = store.getters["mapa/periodo"];

        if (abreviatura === "MC-01.01") {
          esIndicadorMC.ok = true;
          esIndicadorMC.tipo = "gestante";
          // esIndicadorMC.title =
          //   "Proporción de gestantes con paquete preventivo completo - Distritos del Q1yQ2";
          const periodo = store.getters["mapa/periodo"];

          const payload = {
            provincia,
            distrito,
            quintil,
            idperiodoevaluacion: periodo
          };

          EstadisticoApi.paquetePreventivoMC1(payload)
            .then((response) => {
              const valoresGraficas = generaGraficosLine(response.data);
              optionsGrafico.value = optionGraphic("", valoresGraficas);

              const valoresGraficasValor = generaGraficosLineValor(response.data);
              optionsGraficoValor.value = optionGraphicValor("", valoresGraficasValor);
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              escalaColoresPaquete.value = generaLeyendaPaquete();
            });

          ApiIndicador.getPaqueteIndicadorMC1(periodo, distrito, provincia, quintil)
            .then((response) => {
              const temporal = [
                {
                  id: "examen",
                  valor: response.data[0].examen,
                  texto: "Gestantes con 04 exámenes auxilares en el 1er trimestre"
                },
                {
                  id: "prenatal",
                  valor: response.data[0].prenatal,
                  texto: "Gestantes con 04 o más atenciones prenatales"
                },
                {
                  id: "suplemento",
                  valor: response.data[0].suplemento,
                  texto: "Gestantes con suplemento (sulfato ferroso mas ácido fólico)"
                }
              ];

              paquetesIntegrados.value = temporal;
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (abreviatura === "MC-02.01") {
          esIndicadorMC.ok = true;
          esIndicadorMC.tipo = "ninio";
          esIndicadorMC.title =
            "Proporción de niños menores de 1año con paquete preventivo completo - Tacna";

          const payload = {
            provincia,
            distrito,
            quintil,
            idperiodoevaluacion: periodo
          };

          EstadisticoApi.paquetePreventivoMC2(payload)
            .then((response) => {
              const valoresGraficas = generaGraficosLineNinio(response.data);
              optionsGrafico.value = optionGraphicNinio("", valoresGraficas);

              const valoresGraficasValor = generaGraficosLineNinioValor(response.data);
              optionsGraficoValor.value = optionGraphicNinioValor("", valoresGraficasValor);
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              escalaColoresPaquete.value = generaLeyendaPaqueteNinio();
            });

          ApiIndicador.getPaqueteIndicadorMC2(periodo, distrito, provincia, quintil)
            .then((response) => {
              const temporal = [
                {
                  id: "numerocred",
                  valor: response.data[0].numerocred,
                  texto:
                    "Niñas y niños con control de crecimiento y desarrollo (CRED) según su edad"
                },
                {
                  id: "numerovacuna",
                  valor: response.data[0].numerovacuna,
                  texto:
                    "Niñas y niños con vacunas básicas completas (antipolio, pentavalente, neumococo y rotavirus)."
                },
                {
                  id: "numerosuplemento",
                  valor: response.data[0].numerosuplemento,
                  texto:
                    "Niñas y niños con suplemento completo para su edad (hierro en gotas, jarabe o micronutrientes)"
                },
                {
                  id: "numerodosaje",
                  valor: response.data[0].numerodosaje,
                  texto:
                    "Niñas y niños con dosaje de hemoglobina según su edad (realizado entre los 6 a 8 meses de edad)"
                }
              ];

              paquetesIntegrados.value = temporal;
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else {
        esIndicadorMC.ok = false;
        esIndicadorMC.tipo = "";
        esIndicadorMC.title = "";
      }
    };

    const fetchPeriodos = async (sigla) => {
      var response = await ApiIndicador.getperiodoByIndicador(sigla);
      periodos.value = response.data;

      var periodosValue = toRaw(periodos.value);

      var periodoEval = periodosValue[periodosValue.length - 1];
      var idperiodoevaluacion = periodoEval?.idperiodoevaluacion || "";
      idperiodoinicio.value = idperiodoevaluacion;

      rangoPeriodo.value = calculaRangoPeriodos(periodosValue);

      txt = calculaRangoPeriodos(periodosValue);

      store.dispatch("mapa/setPeriodo", idperiodoevaluacion);
    };

    const getEscalaColoresMapa = async () => {
      const res = await MapaApi.getSemaforoMapaPublicv2(idindicador.value);

      coloresSemaforo.RANGO_SINDATO = res.data.find((p) => p.clave === "RANGO_SINDATO").descripcion;
      coloresSemaforo.RANGO_BAJO = res.data.find((p) => p.clave === "RANGO_BAJO").descripcion;
      coloresSemaforo.RANGO_MEDIO = res.data.find((p) => p.clave === "RANGO_MEDIO").descripcion;
      coloresSemaforo.RANGO_ALTO = res.data.find((p) => p.clave === "RANGO_ALTO").descripcion;

      coloresSemaforoMapa.RANGO_SINDATO = res.data.find((p) => p.clave === "RANGO_SINDATO").valor;
      coloresSemaforoMapa.RANGO_BAJO = res.data.find((p) => p.clave === "RANGO_BAJO").valor;
      coloresSemaforoMapa.RANGO_MEDIO = res.data.find((p) => p.clave === "RANGO_MEDIO").valor;
      coloresSemaforoMapa.RANGO_ALTO = res.data.find((p) => p.clave === "RANGO_ALTO").valor;

      escalaColores.value = generaLeyendaSemaforo(toRaw(coloresSemaforo));
    };

    onMounted(() => {
      getResumenIndicador();
      getEscalaColoresMapa();
    });

    onUnmounted(() => {
      store.dispatch("mapa/cleanFilters");
    });

    watch(store.state.periodoevaluacion, (periodoevaluacion) => {
      isPeriodoProcessing.value = periodoevaluacion.isProcessing;
      getResumenIndicador();
    });

    const changeprovincia = () => {
      getResumenIndicador();
    };

    const changeGrafica = () => {
      const ubigeo = store.getters["mapa/ubigeo"];

      if (!ubigeo) {
        fetchIndicadorByIdPeriodo();
      } else {
        MapaApi.getDistrito(ubigeo)
          .then((response) => {
            const distrito = response.data[0].distrito;
            store.dispatch("mapa/setDistrito", distrito);
            fetchIndicadorByIdPeriodo();
          })
          .catch((err) => console.log(err));
      }
    };

    const changedistrito = () => {
      const ubigeo = store.getters["mapa/ubigeo"];

      if (!ubigeo) {
        const distrito = "";
        store.dispatch("mapa/setDistrito", distrito);

        fetchDataToTable();
      } else {
        MapaApi.getDistrito(ubigeo)
          .then((response) => {
            const distrito = response.data[0].distrito;
            store.dispatch("mapa/setDistrito", distrito);
            fetchDataToTable();
          })
          .catch((err) => console.log(err));
      }
    };

    const fetchDataToTable = () => {
      const periodo = store.getters["mapa/periodo"];
      const provincia = store.getters["mapa/provincia"];
      const quintil = store.getters["mapa/quintil"];
      const distrito = store.getters["mapa/distrito"];

      if (quintil == "") {
        esIndicadorMC.title = "Proporción de gestantes con paquete preventivo completo - TODOS";
      } else if (quintil == 1) {
        esIndicadorMC.title =
          "Proporción de gestantes con paquete preventivo completo - Con Distritos del Q1yQ2";
      } else if (quintil == 0) {
        esIndicadorMC.title =
          "Proporción de gestantes con paquete preventivo completo - Sin Distritos del Q1yQ2";
      }

      isLoading.value = true;

      ApiIndicador.getIndicador(distrito, periodo, indicadorData.sigla, provincia, quintil)
        .then((response) => {
          if (periodo == "") {
            rangoPeriodo.value = txt;
          } else {
            if (response.data.length > 0)
              if (response.data[0].idperiodo.length > 0) {
                var anio = response.data[0].idperiodo.substr(0, 4);
                var mes = response.data[0].idperiodo.substr(4, 2);

                rangoPeriodo.value = anio + "/" + mes + " al " + anio + "/" + mes;
              }
          }

          const conPorcentajes = response.data.map((p) => {
            const porcentaje = calculaPorcentaje(
              p.total_numerador_establecimiento,
              p.total_denominador_establecimiento
            );

            const porcentajeHex = semaforoMapa(
              coloresSemaforoMapa,
              p.total_denominador_establecimiento,
              porcentaje
            );

            return {
              ...p,
              porcentaje,
              porcentajeHex
            };
          });

          establecimientos.value = conPorcentajes;

          if (establecimientos.value.length > 0) {
            porcentaje.value = calculaPorcentaje(
              response.data[0].total_numerador_distrito,
              response.data[0].total_denominador_distrito
            );

            porcentajeColor.value = semaforoByPorcentajeIndicador(
              coloresSemaforoMapa.RANGO_SINDATO,
              coloresSemaforoMapa.RANGO_BAJO,
              coloresSemaforoMapa.RANGO_MEDIO,
              porcentaje.value
            );

            numerador.value = response.data[0].total_numerador_distrito;
            denominador.value = response.data[0].total_denominador_distrito;
          } else {
            porcentaje.value = 0;
            numerador.value = 0;
            denominador.value = 0;
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
          isLoading.value = false;
          const periodo = store.getters["mapa/periodo"];

          if (periodo.length == 0) changeGrafica();
          else changeGraficaPeriodo(periodo);
          fetchPaqueteIntegrado(indicadorData.abreviatura);
          fetchDataToTableDistrito();
        });
    };

    const fetchDataToTableDistrito = () => {
      const periodo = store.getters["mapa/periodo"];
      const provincia = store.getters["mapa/provincia"];
      const quintil = store.getters["mapa/quintil"];
      const distrito = store.getters["mapa/distrito"];

      const activePeriodo = toRaw(periodos.value).find((p) => p.idperiodoevaluacion === periodo);

      ApiIndicador.getIndicadorIndicadorInterno(
        distrito,
        indicadorData.sigla,
        provincia,
        activePeriodo?.idperiodoinicio,
        activePeriodo?.idperiodofinal,
        quintil
      )
        .then((response) => {
          console.log(response);
          const conPorcentajes = response.data.map((p) => {
            const porcentaje = calculaPorcentaje(p.total_numerador, p.total_denominador);

            const porcentajeHex = semaforoMapa(
              coloresSemaforoMapa,
              p.total_denominador,
              porcentaje
            );

            return {
              ...p,
              porcentaje,
              porcentajeHex
            };
          });

          distritos.value = conPorcentajes;
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const fetchIndicadorByIdPeriodo = () => {
      const provincia = store.getters["mapa/provincia"];
      const distrito = store.getters["mapa/distrito"];
      const periodo = store.getters["mapa/periodo"];
      const quintil = store.getters["mapa/quintil"];

      isLoadingGrafico.value = true;

      ApiIndicador.getIndicadorByIdperiodo(
        distrito,
        toRaw(idindicador.value),
        toRaw(indicadorData.sigla),
        provincia,
        periodo,
        quintil
      )
        .then(async (response) => {
          const argumento = await generaArgumentoOptionsBar(response.data);

          localOptionGraphic.value = optionsBar(
            argumento.puntosEntrada,
            argumento.numerador,
            argumento.denominador,
            argumento.tendencia,
            argumento.max
          );
        })
        .catch((err) => console.log(err))
        .finally(() => (isLoadingGrafico.value = false));
    };

    const changeGraficaPeriodo = (idperiodo) => {
      const ubigeo = store.getters["mapa/ubigeo"];
      const provincia = store.getters["mapa/provincia"];
      const distrito = "";
      isLoadingGrafico.value = true;

      if (ubigeo == "") {
        ApiIndicador.getIndicadorByIdperiodoMeses(
          distrito,
          toRaw(idindicador.value),
          toRaw(indicadorData.sigla),
          provincia,
          idperiodo
        )
          .then(async (response) => {
            const argumento = await generaArgumentoOptionsBar(response.data);

            localOptionGraphic.value = optionsBar(
              argumento.puntosEntrada,
              argumento.numerador,
              argumento.denominador,
              argumento.tendencia,
              argumento.max
            );
          })
          .catch((err) => console.log(err))
          .finally(() => (isLoadingGrafico.value = false));
      } else {
        MapaApi.getDistrito(ubigeo)
          .then((response) => {
            const distrito = response.data[0].distrito;
            ApiIndicador.getIndicadorByIdperiodoMeses(
              distrito,
              toRaw(idindicador.value),
              toRaw(indicadorData.sigla),
              provincia,
              idperiodo
            )
              .then(async (response) => {
                const argumento = await generaArgumentoOptionsBar(response.data);

                localOptionGraphic.value = optionsBar(
                  argumento.puntosEntrada,
                  argumento.numerador,
                  argumento.denominador,
                  argumento.tendencia,
                  argumento.max
                );
              })
              .catch((err) => console.log(err))
              .finally(() => (isLoadingGrafico.value = false));
          })
          .catch((err) => console.log(err));
      }
    };

    const changePeriodo = () => {
      fetchDataToTable();
    };

    const handleReporteExcel = () => {
      const provincia = store.getters["mapa/provincia"];
      const distrito = store.getters["mapa/distrito"];
      const periodo = store.getters["mapa/periodo"];
      const quintil = store.getters["mapa/quintil"];

      var titulo = toRaw(indicadorData.denominacion);

      if (provincia.length > 0) {
        titulo = titulo + " [ PROVINCIA : " + provincia + " ]";
      }

      if (distrito.length > 0) {
        titulo = titulo + " [ DISTRITO : " + distrito + " ]";
      }

      ApiDescargas.getReporteIndicadorv2Indicador(
        toRaw(indicadorData.sigla),
        "indicador_reporte",
        titulo,
        numerador.value,
        denominador.value,
        toRaw(porcentaje.value),

        provincia,
        distrito,
        periodo,
        quintil
      ).catch((err) => console.log(err));
    };

    const customRow = (record) => {
      return {
        onClick: () => {
          store.dispatch("graficasEstablecimientos/setMicrored", record.microred);
          router.push(`/indicador/${idindicador.value}/establecimiento/${record.establecimiento}`);
        }
      };
    };

    const showModalPrintModal = () => {
      isVisiblePrintModal.value = true;
    };
    const closeModalPrintModal = () => {
      isVisiblePrintModal.value = false;
    };

    const showModalPeriodoEvaluacion = () => {
      isVisible.value = true;
    };

    const closeModalPeriodoEvaluacion = () => {
      isVisible.value = false;
    };

    return {
      idindicador,
      establecimientos,
      closeModalPeriodoEvaluacion,
      isVisible,
      porcentaje,
      numerador,
      changedistrito,
      showModalPeriodoEvaluacion,
      denominador,
      changePeriodo,
      indicadorData,
      isLoading,
      getResumenIndicador,
      isAccionsDisabled,
      handleReporteExcel,
      isLoadingGrafico,
      calculaPorcentaje,
      localOptionGraphic,
      periodos,
      isPeriodoProcessing,
      changeprovincia,
      columnsIndicadorEstablecimiento,
      customRow,
      objPrintIndicadorResumen,
      isVisiblePrintModal,
      showModalPrintModal,
      closeModalPrintModal,
      idperiodoinicio,
      porcentajeColor,
      paquetesIntegrados,
      calculaRangoPeriodos,
      rangoPeriodo,
      escalaColores,
      distritos,
      columnsIndicadorDistrito,
      esIndicadorMC,
      escalaColoresPaquete,
      optionsGrafico,
      optionsGraficoValor
    };
  }
};
</script>

<style></style>
