import { post, put } from "./base";
const API_PUBLIC = "/api/Prueba/V1/query";
const API_PUBLIC_MULTI = "/api/public/V1/multiquery";
const API_SERVICE = "/api/sivi_data/V1";
const API_SERVICE_PUBLIC_MULTIQUERY = "/api/public/V1/multiquery";

export default {
  getAll: (idtipodeficiencia = "1", filter = "all") => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_detalle_mas_estadistico",
          type: "string"
        },
        {
          name: "@filtros_AND",
          value: "idtipodeficiencia",
          type: "string"
        },
        {
          name: "@buscar_AND",
          value: `${idtipodeficiencia}`,
          type: "int"
        }
      ]
    };

    if (filter !== "all") {
      request._petitions.push({
        name: "@filtros_OR",
        value: "activo",
        type: "string"
      });

      request._petitions.push({
        name: "@buscar_OR",
        value: `${filter}`,
        type: "string"
      });
    }

    return post(API_PUBLIC, request);
  },

  addOne: (payload) => {
    const request = {
      _type: 2,
      _table: "mas_estadistico",
      _petitions: [
        {
          name: "anio",
          value: payload.anio,
          type: "int"
        },
        {
          name: "valorlocal",
          value: payload.valorlocal,
          type: "int"
        },
        {
          name: "valornacional",
          value: payload.valornacional,
          type: "int"
        },
        {
          name: "idtipodeficiencia",
          value: payload.idtipodeficiencia,
          type: "int"
        }
      ]
    };

    // console.log(request);

    return post(API_SERVICE, request);
  },

  editOne: (payload, idestadistico) => {
    const request = {
      _type: 2,
      _table: "mas_estadistico",
      _petitions: [
        {
          name: "anio",
          value: payload.anio,
          type: "int"
        },
        {
          name: "valorlocal",
          value: payload.valorlocal,
          type: "int"
        },
        {
          name: "valornacional",
          value: payload.valornacional,
          type: "int"
        },
        {
          name: "idtipodeficiencia",
          value: payload.idtipodeficiencia,
          type: "int"
        },
        {
          name: "activo",
          value: payload.activo,
          type: "int"
        }
      ]
    };
    return put(API_SERVICE + "/" + idestadistico, request);
  },

  changeState: (idestadistico, activo = "0") => {
    const request = {
      _type: 3,
      _table: "mas_estadistico",
      _petitions: [
        {
          name: "activo",
          value: activo,
          type: "int"
        }
      ]
    };

    return put(API_SERVICE + "/" + idestadistico, request);
  },

  deleteOne: (idestadistico) => {
    const request = {
      _type: 3,
      _table: "mas_estadistico",
      _petitions: [
        {
          name: "eliminado",
          value: "1",
          type: "int"
        }
      ]
    };

    return put(API_SERVICE + "/" + idestadistico, request);
  },

  paquetePreventivoMC1(payload) {
    const request = {
      _type: 1,
      _petitions: [
        {
          name: "_script",
          value: payload.idperiodoevaluacion
            ? "web_data_select_paquete_preventivo_mc1_interno"
            : "web_data_select_paquete_preventivo_mc1",
          type: "string"
        },
        {
          name: "@PROVINCIA",
          value: `%${payload.provincia}%`,
          type: "string"
        },
        {
          name: "@DISTRITO",
          value: `%${payload.distrito}%`,
          type: "string"
        },
        {
          name: "@QUINTIL",
          value: `%${payload.quintil}%`,
          type: "string"
        }
      ]
    };

    request._petitions.push({
      name: "@PERIODO_INI",
      value: payload.periodo_ini,
      type: "string"
    });


    request._petitions.push({
      name: "@PERIODO_FIN",
      value: payload.periodo_fin,
      type: "string"
    });


    request._petitions.push({
      name: "@IDPERIODO",
      value: `%${payload.idperiodoevaluacion}%`,
      type: "string"
    });

    return post(API_SERVICE_PUBLIC_MULTIQUERY, request);
  },

  paquetePreventivoMC1FiltroAnio(payload) {
    const request = {
      _type: 1,
      _petitions: [
        {
          name: "_script",
          value: "web_data_select_paquete_preventivo_mc1_filtro_anio",
          type: "string"
        },
        {
          name: "@ANIO",
          value: `%${payload}%`,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_PUBLIC_MULTIQUERY, request);
  },

  paquetePreventivoMC2(payload) {
    const request = {
      _type: 1,
      _petitions: [
        {
          name: "_script",
          value: payload.idperiodoevaluacion
            ? "web_data_select_paquete_preventivo_mc2_interno"
            : "web_data_select_paquete_preventivo_mc2",
          type: "string"
        },
        {
          name: "@PROVINCIA",
          value: `%${payload.provincia}%`,
          type: "string"
        },
        {
          name: "@DISTRITO",
          value: `%${payload.distrito}%`,
          type: "string"
        },
        {
          name: "@QUINTIL",
          value: `%${payload.quintil}%`,
          type: "string"
        }
      ]
    };

    request._petitions.push({
      name: "@PERIODO_INI",
      value: payload.periodo_ini,
      type: "string"
    });


    request._petitions.push({
      name: "@PERIODO_FIN",
      value: payload.periodo_fin,
      type: "string"
    });


    request._petitions.push({
      name: "@IDPERIODO",
      value: `%${payload.idperiodoevaluacion}%`,
      type: "string"
    });

    return post(API_PUBLIC_MULTI, request);
  },

  paquetePreventivoMC2FiltroAnio(payload) {
    const request = {
      _type: 1,
      _petitions: [
        {
          name: "_script",
          value: "web_data_select_paquete_preventivo_mc2_filtro_anio",
          type: "string"
        },
        {
          name: "@ANIO",
          value: `%${payload}%`,
          type: "string"
        },
      ]
    };

    return post(API_PUBLIC_MULTI, request);
  }
};
