<template>
  <div class="w-full bg-white rounded-md p-3 mb-4">
    <h1 v-if="title" class="text-center text-base font-semibold mt-2">{{ title }}</h1>
    <a-radio-group v-model:value="isTendencia">
      <a-radio-button :value="true">Tendencia</a-radio-button>
      <!-- <a-radio-button :value="false">Valores</a-radio-button> -->
    </a-radio-group>

    <v-chart v-if="isTendencia" :option="optionsGrafico" class="w-full h-128" />

    <v-chart v-else :option="optionsGraficoValor" class="w-full h-128" />

    <div class="md:ml-24">
      <h4>Leyenda de gráfico:</h4>
      <div class="md:flex md:justify-between md:gap-2">
        <div
          v-for="escala in escalaColores"
          :key="escala.texto"
          class="flex gap-2 items-center text-xs"
        >
          <div
            class="w-6 h-2 border border-gray-500 my-5 md:my-0"
            :style="{ backgroundColor: escala.color }"
          ></div>
          {{ escala.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    escalaColores: {
      type: Array,
      default: () => []
    },
    optionsGrafico: {
      type: Object,
      default: null
    },
    optionsGraficoValor: {
      type: Object,
      default: null
    }
  },
  setup() {
    const isTendencia = ref(true);
    return {
      isTendencia
    };
  }
};
</script>
